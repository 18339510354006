import React, { useState, useCallback, useEffect } from 'react';
import useWebSocket from './useWebSocket';
import { WebSocketProvider } from './WebSocketContext';

const AppContent = ({ userId }) => {
  console.log("App.js userId");
  console.log(userId);
  const initialNotifications = JSON.parse(localStorage.getItem('notifications') || '[]');
  const [notifications, setNotifications] = useState(initialNotifications);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const hasUnreadNotifications = initialNotifications.length > 0 && JSON.parse(localStorage.getItem('isNewNotification') || 'false');
  const [isNewNotification, setIsNewNotification] = useState(hasUnreadNotifications);


  const handleBellClick = () => {
    setIsNewNotification(false); // remove the red dot
    setDropdownVisible(!isDropdownVisible);
  };

  const handleNewMessage = useCallback((notification) => {
    if (notification.notification_type === 'new_chat_message') {
      setIsNewNotification(true); // Set new notification flag on new message
      setNotifications((prevNotifications) => {
        const newNotifications = [notification, ...prevNotifications];

        // Ensure there are never more than 10 notifications
        if (newNotifications.length > 10) {
          newNotifications.length = 10; // Trim to keep only the 10 latest
        }

        return newNotifications;
      });
    }
  }, []);

  useWebSocket(userId, handleNewMessage);

  const className = `header-button-item ${isNewNotification ? 'has-noti' : ''} js-item-menu ${isDropdownVisible ? 'show-dropdown' : ''}`;

  const handleNotificationClick = (index) => {
    console.log("Notification at index", index, "clicked");
    setNotifications((prevNotifications) => {
      const updated = [...prevNotifications];
      updated.splice(index, 1);
      return updated;
    });
  };

  // Update localStorage when notifications or its viewed status change
  useEffect(() => {
    console.log("Notifications updated:", notifications);
    localStorage.setItem('notifications', JSON.stringify(notifications));
    localStorage.setItem('isNewNotification', JSON.stringify(isNewNotification));
  }, [notifications, isNewNotification]);

  return (
    <div className={className}>
      <i className="ion-ios-bell-outline" onClick={handleBellClick}></i>
      <div className="notifi-dropdown js-dropdown" onClick={(e) => e.stopPropagation()}>
        <div className="notifi__title">
          <p>You have {notifications.length} Notifications</p>
        </div>
        {notifications.map((notification, index) => (
          <div
            className="notifi__item"
            key={index}
            onClick={() => handleNotificationClick(index)}
          >
            <div className="bg-c1 red">
              <i className="icofont-check"></i>
            </div>
            <div className="content">
              <a href="/api/messages/">
                {notification.message} {notification.username}
              </a>
            </div>
          </div>
        ))}
      </div>
    </div >
  );
};

const App = () => {
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    fetch('/api/user_id/')
      .then(response => response.json())
      .then(data => setUserId(data.user_id))
      .catch(err => console.error("Failed to fetch user ID:", err));
  }, []);

  return (
    <WebSocketProvider userId={userId}>
      <AppContent userId={userId} />
    </WebSocketProvider>
  );
};

export default App;




/*const App = () => {
  const [notifications, setNotifications] = useState([]);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [userId, setUserId] = useState(null);

  const handleNewMessage = useCallback((notification) => {
    if (notification.notification_type === 'new_chat_message') {
      const receivedUserId = notification.data.user_id;
      console.log("Received user_id from notification:", receivedUserId);
      if (!userId) {
        setUserId(receivedUserId);
        console.log("Received user_id from notification:", receivedUserId);
      } else {
        console.log("Received user_id from notification:", userId);
      }
      setNotifications((prevNotifications) => [notification, ...prevNotifications]);
    }
  }, [userId]);

  // Fetch the user ID from the backend
  useEffect(() => {
    fetch('/api/user_id/')
      .then(response => response.json())
      .then(data => {
        setUserId(data.user_id);
      })
      .catch(err => console.error("Failed to fetch user ID:", err));
  }, []);

  // Use the WebSocket hook with the userId
  useWebSocket(userId, handleNewMessage);

  const className = `header-button-item ${notifications.length > 0 ? 'has-noti' : ''} js-item-menu ${isDropdownVisible ? 'show-dropdown' : ''}`;

  return (
    <div className={className} onClick={() => setDropdownVisible(!isDropdownVisible)}>
      <i className="ion-ios-bell-outline"></i>
      <div className="notifi-dropdown js-dropdown">
        <div className="notifi__title">
          <p>You have {notifications.length} Notifications</p>
        </div>
        {notifications.map((notification, index) => (
          <div className="notifi__item" key={index}>
            <div className="bg-c1 red">
              <i className="icofont-check"></i>
            </div>
            <div className="content">
              <p>{notification.message}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default App;*/







// Only import what's needed
/*import React, { useState, useCallback } from 'react';
//import NotificationBell from './NotificationBell';
import useWebSocket from './useWebSocket';

const App = () => {

  console.log('App component rendered');
  console.log("App component file is being executed");
  const [notifications, setNotifications] = useState([]);

  const handleNewMessage = useCallback((notification) => {
    console.log('handleNewMessage executed', notification);
    if (notification.notification_type === 'new_chat_message') {
      console.log('handleNewMessage executed inside if', notification);
      setNotifications((prevNotifications) => [notification, ...prevNotifications]);
    }
  }, []);

  // Using your custom hook
  useWebSocket('ws://localhost:8000/api/notifications/user_2/', handleNewMessage);

  const className = `header-button-item ${notifications.length > 0 ? 'has-noti' : ''} js-item-menu`;

  return (
    <div className={className}>
      <i className="ion-ios-bell-outline"></i>
      <div className="notifi-dropdown js-dropdown">
        <div className="notifi__title">
          <p>You have {notifications.length} Notifications</p>
        </div>
        {notifications.map((notification, index) => (
          <div className="notifi__item" key={index}>
            <div className="bg-c1 red">
              <i className="icofont-check"></i>
            </div>
            <div className="content">
              <p>{notification.message}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default App;*/




// Only import what's needed
//import React, { useState, useCallback } from 'react';
//import NotificationBell from './NotificationBell';
//import useWebSocket from './useWebSocket';

/*import React from 'react';

const App = () => {
  return (
    <h1>Hello from React</h1>
  );
};

export default App;*/




