import { useContext, useEffect } from 'react';
import { WebSocketContext } from './WebSocketContext';  // Only import what you're using


const useWebSocket = (userId, handleNewMessage) => {
    const websocket = useContext(WebSocketContext);

    useEffect(() => {
        if (!userId || !websocket) return;

        websocket.onmessage = (event) => {
            const message = JSON.parse(event.data);
            handleNewMessage(message);
        };

    }, [userId, handleNewMessage, websocket]);
};

export default useWebSocket;



/*import { useContext, useEffect } from 'react';
import { WebSocketContext } from './WebSocketContext';

const useWebSocket = (userId, handleNewMessage) => {
    const websocket = useContext(WebSocketContext);

    useEffect(() => {
        if (!userId || !websocket) return;

        websocket.onmessage = (event) => {
            const message = JSON.parse(event.data);
            handleNewMessage(message);
        };

        // Since we're not creating the WebSocket in this hook anymore, we don't need a cleanup function.
    }, [userId, handleNewMessage, websocket]);
};

export default useWebSocket;*/





/*import { useEffect } from 'react';

const useWebSocket = (userId, handleNewMessage) => {
    useEffect(() => {
        if (!userId) return;  // If no userId, don't establish a connection

        const wsUrl = `ws://localhost:8000/api/notifications/user_${userId}/`;
        console.log("WebSocket URL:", wsUrl);

        const socket = new WebSocket(wsUrl);

        socket.onopen = () => {
            console.log('WebSocket connected');
        };

        socket.onclose = () => {
            console.log('WebSocket disconnected');
        };

        socket.onmessage = (event) => {
            const message = JSON.parse(event.data);
            handleNewMessage(message);
        };

        socket.onerror = (error) => {
            console.error('WebSocket Error:', error);
        };

        // Cleanup: close the WebSocket when the component is unmounted or when userId changes
        return () => {
            socket.close();
        };
    }, [userId, handleNewMessage]);
};

export default useWebSocket;*/



/*import { useEffect } from 'react';

const useWebSocket = (url, onMessage) => {
    useEffect(() => {
        const socket = new WebSocket(url);

        console.log('Component mounted or updated');
        console.log('WebSocket URL:', url);
        console.log('onMessage:', onMessage);

        socket.onopen = () => {
            console.log('WebSocket connected');
        };

        socket.onclose = () => {
            console.log('WebSocket disconnected');
        };

        socket.onmessage = (event) => {
            const message = JSON.parse(event.data);
            onMessage(message);
        };

        socket.onerror = (error) => {
            console.error('WebSocket Error:', error);
        };

        return () => {
            socket.close();
        };
    }, [url, onMessage]);
};

export default useWebSocket;*/



/*import { useEffect } from 'react';

const useWebSocket = (url, onMessage) => {
    useEffect(() => {
        console.log('Component mounted or updated');
        console.log('WebSocket URL:', url);
        console.log('onMessage:', onMessage);
        const socket = new WebSocket(url);

        socket.onopen = () => {
            console.log('WebSocket connected');
        };

        socket.onclose = () => {
            console.log('WebSocket disconnected');
        };

        socket.onmessage = (event) => {
            console.log('WebSocket received:', event);
            const message = JSON.parse(event.data);
            onMessage(message);
        };

        socket.onerror = (error) => {
            console.error('WebSocket Error:', error);
        };

        return () => {
            socket.close();
        };
    }, [url, onMessage]);
};

export default useWebSocket;*/
