import React, { createContext, useState, useEffect } from 'react';

export const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children, userId }) => {
    const [socket, setSocket] = useState(null);

    useEffect(() => {
        console.log("Websocket notification react before");
        console.log(userId);
        if (!userId) return;  // Ensure you don't create a WebSocket if userId isn't present
        console.log("Websocket notification react after");
        console.log(userId);
        // Initialize WebSocket connection here
        const wsUrl = `wss://fanto.work/api/notifications/${userId}/`;
        const websocket = new WebSocket(wsUrl);

        websocket.onopen = () => {
            console.log("WebSocket is now open!");
        };

        websocket.onmessage = (event) => {
            console.log("Received WebSocket message:", event.data);
        };

        websocket.onerror = (errorEvent) => {
            console.error("WebSocket Error:", errorEvent);
    
            if (errorEvent.target && errorEvent.target.readyState) {
                 console.error("WebSocket ReadyState:", errorEvent.target.readyState);
            }
    
            if (errorEvent.message) {
                 console.error("WebSocket Error Message:", errorEvent.message);
            }
        };


        websocket.onclose = (event) => {
            console.error("WebSocket Closed:", event.code, event.reason);

            if (websocket.readyState === WebSocket.OPEN) {
                console.error("WebSocket was previously opened.");
            } else {
                console.error("WebSocket was never successfully opened.");
            }

            // Reconnection logic
            setTimeout(() => {
                setSocket(new WebSocket(wsUrl));
            }, 5000); // try to reconnect every 5 seconds
        };

        // Heartbeats or pings to ensure connection stays alive
        const heartbeatInterval = setInterval(() => {
            if (websocket.readyState === WebSocket.OPEN) {
                websocket.send("ping");  // "ping" is just an example message
            }
        }, 10000); // send a message every 10 seconds

        setSocket(websocket);

        return () => {
            clearInterval(heartbeatInterval);  // clear the interval when component is unmounted
            if (websocket) {
                websocket.close();
            }
        };
    }, [userId]);  // Added userId as a dependency so if it changes, this effect will run again

    return (
        <WebSocketContext.Provider value={socket}>
            {children}
        </WebSocketContext.Provider>
    );
};

