import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

const appRoot = document.getElementById('app-root');
console.log("Index.js is being executed");

if (appRoot) {
  console.log('App.js is being executed.');
  console.log("Before rendering React");
  console.log(document.getElementById('app-root'));
  ReactDOM.render(<App />, appRoot);
  console.log("After rendering React");
}


/*

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

document.addEventListener('DOMContentLoaded', function () {
  const appRoot = document.getElementById('app-root');
  console.log("Index.js is being executed");

  if (appRoot) {
    console.log('App.js is being executed.');
    console.log("Before rendering React");
    console.log(appRoot);  // Note: we're now using the appRoot variable directly.
    ReactDOM.render(<App />, appRoot);
    console.log("After rendering React");
  }
});


*/

/*import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

const appRoot = document.getElementById('root');

if (appRoot) {
  ReactDOM.render(<App />, appRoot);
}*/


/*import React from 'react';
import ReactDOM from 'react-dom';

const appRoot = document.getElementById('root');

if (appRoot) {
  ReactDOM.render(<h1>Hello directly from index.js</h1>, appRoot);
}*/



/*import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

const appRoot = document.getElementById('root');

if (appRoot) {
  console.log("Before rendering React");
  console.log(document.getElementById('root'));

  ReactDOM.render(<App />, appRoot);

  console.log("After rendering React");
}*/












/*import { createRoot } from 'react-dom';
import React from 'react';
import App from './App';
//import NotificationBell from './NotificationBell';

const appRoot = document.getElementById('app-root');
//const notificationRoot = document.getElementById('notification-root');

if (appRoot) {
  console.log("Before rendering React");
  console.log(document.getElementById('app-root'));
  const root = createRoot(appRoot);
  root.render(<App />);
  console.log("After rendering React");

}*/

/*if (notificationRoot) {
  console.log(document.getElementById('notification-root'));
  const notificationRootDom = createRoot(notificationRoot);
  notificationRootDom.render(<NotificationBell newNotifications={true} />);
}*/






/*import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import NotificationBell from './NotificationBell';

// Render App component into 'app-root' div
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('app-root')
);

// Render NotificationBell component into 'notification-root' div
// Note: you'll have to implement logic to set newNotifications correctly.
ReactDOM.render(
  <React.StrictMode>
    <NotificationBell newNotifications={true} />
  </React.StrictMode>,
  document.getElementById('notification-root')
);*/




/*import ReactDOM from "react-dom/client"; // new for version 18
//import { useState } from 'React';
//import React from 'React';
import { StrictMode } from 'react';
//import { createRoot } from 'react-dom/client'; // or new for version 18, but not working here
import axios from 'axios';
import App from './App';

const API_URL = 'http://localhost:8000/router/'; // updated to point to your Django backend API

axios.get(API_URL)
  .then(response => {
    console.log(response.data); // log the response data to the console
    const root = ReactDOM.createRoot(document.getElementById("root"))
    root.render(<StrictMode><App data={response.data} /></StrictMode>);
  })
  .catch(error => {
    console.error(error); // log any errors to the console
  });*/
